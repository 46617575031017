import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import EditContentTab from "../EditContentTab";
import EditButton from "../../EditButton";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";
import { useCallback, useEffect, useRef } from "react";

interface ScenesStageProps {
  content: {
    Scenes: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      scenes: any[];
    }[];
  };
  characters: string[];
  storyId: string;
  setIsCharacterBlueprintsPollingFinished: (value: boolean) => void;
  isCharacterBlueprintsPollingFinished: boolean;
}

const ScenesStage = ({
  content,
  characters,
  storyId,
  setIsCharacterBlueprintsPollingFinished,
  isCharacterBlueprintsPollingFinished,
}: ScenesStageProps) => {
  const { getToken } = useAuth();

  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);
  const MAX_POLL_ATTEMPTS = 3;
  const pollCountRef = useRef(0);
  const POLLING_INTERVAL = 40000; // 40 seconds

  const checkCharacterBlueprints = useCallback(async () => {
    try {
      const token = await getToken();
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/check-character-blueprints-ready`,
        { storyId, characters },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        },
      );

      // If there are no missing characters, blueprints are ready
      if (response.data.missing_characters?.length === 0) {
        setIsCharacterBlueprintsPollingFinished(true);
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error checking character blueprints:", error);
      return false;
    }
  }, [getToken, storyId, characters, setIsCharacterBlueprintsPollingFinished]);

  useEffect(() => {
    if (!content || isCharacterBlueprintsPollingFinished) {
      return;
    }

    const startPolling = async () => {
      // Initial check
      const isReady = await checkCharacterBlueprints();
      if (isReady) {
        return;
      }

      // Start polling if not ready
      if (!intervalIdRef.current) {
        intervalIdRef.current = setInterval(async () => {
          if (pollCountRef.current >= MAX_POLL_ATTEMPTS) {
            console.log("Reached maximum retry attempts. Stopping polling.");
            // set to true to show next button
            setIsCharacterBlueprintsPollingFinished(true);
            if (intervalIdRef.current) {
              clearInterval(intervalIdRef.current);
              intervalIdRef.current = null;
            }
            return;
          }

          console.log(`Polling attempt ${pollCountRef.current + 1}`);
          const isReady = await checkCharacterBlueprints();

          if (isReady) {
            if (intervalIdRef.current) {
              clearInterval(intervalIdRef.current);
              intervalIdRef.current = null;
            }
            return;
          }

          pollCountRef.current += 1;
        }, POLLING_INTERVAL);
      }
    };

    startPolling();

    // Cleanup function
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, isCharacterBlueprintsPollingFinished, checkCharacterBlueprints]);

  return (
    <EditContentTab titleText="Scenes">
      {content && (
        <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
          {!isCharacterBlueprintsPollingFinished &&
          pollCountRef.current < MAX_POLL_ATTEMPTS ? (
            <>
              <CircularProgress size={20} />
              <Typography variant="body2">
                Waiting for character blueprints to be ready!
              </Typography>
            </>
          ) : pollCountRef.current >= MAX_POLL_ATTEMPTS ? (
            <Typography variant="body2">
              Character blueprints generation took too long or failed! Please
              continue with the story generation.
            </Typography>
          ) : null}
        </Box>
      )}
      {content.Scenes.map((sceneGroup, groupIndex) => (
        <Box
          key={groupIndex}
          sx={{
            margin: "8px",
            padding: "16px",
            paddingTop: "0px",
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            borderRadius: 1,
            position: "relative",
            wordBreak: "break-word",
          }}
        >
          <Typography variant="h6" sx={{ color: "white", fontWeight: "bold" }}>
            Scene Group {groupIndex + 1}
          </Typography>
          <Divider
            sx={{
              marginBottom: "10px",
              borderColor: "rgba(255, 255, 255, 0.5)",
            }}
          />

          {sceneGroup.scenes.map((scene) => (
            <Box key={scene.sceneId} sx={{ marginBottom: "16px" }}>
              <Typography
                variant="subtitle1"
                sx={{ color: "rgba(255, 255, 255, 0.9)" }}
              >
                Scene ID: {scene.sceneId}
              </Typography>
              <Divider sx={{ marginBottom: "10px" }} />
              <Box sx={{ color: "rgba(255, 255, 255, 0.7)" }}></Box>
              <Typography
                variant="body2"
                sx={{ color: "rgba(255, 255, 255, 0.7)" }}
              >
                <span
                  style={{
                    color: "rgba(255, 255, 255, 0.9)",
                    fontWeight: "bold",
                  }}
                >
                  Description:
                </span>{" "}
                {scene.description}
                <br />
                <span
                  style={{
                    color: "rgba(255, 255, 255, 0.9)",
                    fontWeight: "bold",
                  }}
                >
                  Location:
                </span>{" "}
                {scene.location}
                <br />
                <span
                  style={{
                    color: "rgba(255, 255, 255, 0.9)",
                    fontWeight: "bold",
                  }}
                >
                  Time of Day:
                </span>{" "}
                {scene.timeOfDay}
                <br />
                <span
                  style={{
                    color: "rgba(255, 255, 255, 0.9)",
                    fontWeight: "bold",
                  }}
                >
                  Characters:
                </span>{" "}
                {scene.characters.join(", ")}
                <br />
                <span
                  style={{
                    color: "rgba(255, 255, 255, 0.9)",
                    fontWeight: "bold",
                  }}
                >
                  Pacing:
                </span>{" "}
              </Typography>
              <ul
                style={{ fontSize: "14px", color: "rgba(255, 255, 255, 0.7)" }}
              >
                <li>
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "rgba(255, 255, 255, 0.9)",
                    }}
                  >
                    Intensity Rating:
                  </span>{" "}
                  {scene.pacing.intensityRating}
                </li>
                <li>
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "rgba(255, 255, 255, 0.9)",
                    }}
                  >
                    Energy:
                  </span>{" "}
                  {scene.pacing.energy}
                </li>
              </ul>
              <Typography
                variant="body2"
                sx={{ color: "rgba(255, 255, 255, 0.9)", fontWeight: "bold" }}
              >
                Instructions:
              </Typography>
              <ol
                style={{
                  fontSize: "14px",
                  color: "rgba(255, 255, 255, 0.7)",
                }}
              >
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                {scene.instructions.map((instruction: any, index: number) => (
                  <li key={index}>{instruction}</li>
                ))}
              </ol>
              <br />
              <Typography
                variant="body2"
                style={{
                  color: "rgba(255, 255, 255, 0.9)",
                  fontWeight: "bold",
                }}
              >
                Image Details:
              </Typography>{" "}
              <br />
              <Divider sx={{ margin: "10px 0" }} />
            </Box>
          ))}
          <Box sx={{ position: "absolute", top: "13px", right: "0px" }}>
            <EditButton />
          </Box>
        </Box>
      ))}
    </EditContentTab>
  );
};

export default ScenesStage;
