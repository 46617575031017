import { useState, useEffect, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { ExtendedScriptLine } from "./StoryDisplay";

type SubtitleDisplayProps = {
  textContent: ExtendedScriptLine[];
  isPlaying: boolean;
  onSceneChange: (sceneId?: string) => void;
};

const SubtitleDisplay = ({
  textContent,
  isPlaying,
  onSceneChange,
}: SubtitleDisplayProps) => {
  const [currentLineIndex, setCurrentLineIndex] = useState(0);
  const [subtitleWidth, setSubtitleWidth] = useState("40%");
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);

  const handleSubtitleTransition = () => {
    if (!isPlaying || currentLineIndex >= textContent.length) return;

    const currentLine = textContent[currentLineIndex];

    if (currentLine.lineType === "SceneChange") {
      onSceneChange(currentLine.sceneId); // Handle scene change
    }

    // Move to the next subtitle after the current line's displayTime
    setTimeout(() => {
      setCurrentLineIndex((prevIndex) =>
        Math.min(prevIndex + 1, textContent.length - 1),
      );
    }, currentLine.displayTime);
  };

  useEffect(() => {
    if (isPlaying) {
      handleSubtitleTransition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying, currentLineIndex]); // Re-run when `isPlaying` or `currentLineIndex` changes

  useEffect(() => {
    if (currentLineIndex >= textContent.length) return;

    const currentText = textContent[currentLineIndex]?.text || "";
    const newWidth =
      currentText.length <= 50
        ? "30%"
        : currentText.length <= 100
          ? "50%"
          : "70%";

    setSubtitleWidth(newWidth);
  }, [currentLineIndex, textContent]);

  useEffect(() => {
    const handleResize = () => setWindowHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const subtitleFontSize = useMemo(() => {
    const fontSize = Math.min(Math.max(windowHeight * 0.02, 11), 16);
    return `${fontSize}px`;
  }, [windowHeight]);

  if (currentLineIndex >= textContent.length) return null;

  const currentLine = textContent[currentLineIndex];

  return (
    <Box
      sx={{
        position: "absolute",
        bottom: "10%",
        left: "50%",
        transform: "translateX(-50%)",
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        color: "white",
        padding: "12px",
        borderRadius: "2px",
        width: subtitleWidth,
        textAlign: "center",
        pointerEvents: "none",
        zIndex: 4,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      aria-live="polite"
    >
      {currentLine.characterName && currentLine.lineType !== "Action" && (
        <Typography
          sx={{ fontWeight: "bold", fontSize: "20px", padding: "4px 0" }}
        >
          {currentLine.characterName}
        </Typography>
      )}
      <Typography
        sx={{
          fontSize: subtitleFontSize,
          fontStyle: currentLine.lineType === "Action" ? "italic" : "normal",
        }}
      >
        {currentLine.text}
      </Typography>
    </Box>
  );
};

export default SubtitleDisplay;
