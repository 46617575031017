import { Box, Divider, Typography } from "@mui/material";
import EditContentTab from "../EditContentTab";
import EditButton from "../../EditButton";

interface StoryBeat {
  title: string | null;
  num: number | null;
  expandedDescription: string | null;
}

interface StoryBeats {
  StoryBeats: StoryBeat[];
}

interface StoryBeatsStageProps {
  content: StoryBeats;
}

const StoryBeatsStage = ({ content }: StoryBeatsStageProps) => {
  console.log("StoryBeats CONTENT:", { content });

  // Helper function to handle null or undefined values
  const displayValue = (value: string | number | null | undefined) =>
    value !== null && value !== undefined ? value : "-";

  return (
    <EditContentTab titleText="Story Beats">
      <Box
        sx={{
          margin: "8px",
          padding: "16px",
          paddingTop: "0px",
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          borderRadius: 1,
          position: "relative",
          wordBreak: "break-word",
        }}
      >
        <Typography variant="h6" sx={{ color: "white", fontWeight: "bold" }}>
          Story Beats
        </Typography>
        <Divider
          sx={{
            marginBottom: "10px",
            borderColor: "rgba(255, 255, 255, 0.5)",
          }}
        />

        {/* Map through the story beats and display each one */}
        {content.StoryBeats.map((beat, index) => (
          <Box key={index} sx={{ marginBottom: "10px" }}>
            <Typography
              variant="body2"
              sx={{ color: "rgba(255, 255, 255, 0.9)", fontWeight: "bold" }}
            >
              {`${displayValue(beat.num)}: ${displayValue(beat.title)}`}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "rgba(255, 255, 255, 0.7)" }}
            >
              {displayValue(beat.expandedDescription)}
            </Typography>
          </Box>
        ))}

        {/* Edit button */}
        <Box sx={{ position: "absolute", top: "13px", right: "0px" }}>
          <EditButton />
        </Box>
      </Box>
    </EditContentTab>
  );
};

export default StoryBeatsStage;
